/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, Navigate, HashRouter } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import { QueryParamProvider } from './QueryParamContext';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  return (
    <HashRouter basename={PUBLIC_URL}>
      <QueryParamProvider>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/appointment' />} />
            </>
          </Route>
        </Routes>
      </QueryParamProvider>
    </HashRouter>
  )
}

export { AppRoutes }
