/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl';

interface InquiryContactUsProps {
  showModal: boolean;
  isSuccess: boolean;
  onClose: () => void;
}

const InquiryContactUs: FC<InquiryContactUsProps> = ({ showModal, isSuccess, onClose }) => {
  const intl = useIntl();
  return (
    <>
    <Modal
      className='modal fade'
      id='modal_inquiry_success'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={showModal && isSuccess}
      dialogClassName='modal-650px'
      aria-hidden='true'
    >
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'MODAL.CONTACT.US.HEADER.SUCCESS'})}</h1>
            </div>
            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='submit' className='btn btn-success' onClick={onClose}>
                {intl.formatMessage({id: 'BUTTON.OK'})}
              </button>
            </div>
          </div>
        </div>
    </Modal>
    <Modal
      className='modal fade'
      id='modal_inquiry_failed'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={showModal && !isSuccess}
      dialogClassName='modal-650px'
      aria-hidden='true'
    >
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'MODAL.CONTACT.US.HEADER.FAILED'})}</h1>
            </div>
            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='submit' className='btn btn-danger' onClick={onClose}>
                {intl.formatMessage({id: 'BUTTON.OK'})}
              </button>
            </div>
          </div>
        </div>
    </Modal>
    </>
  )
}

export {InquiryContactUs}
