import { ErrorMessage, Field, Formik, Form, FormikValues } from "formik";
import { useState } from "react";
import { KTIcon } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import { ICreateContactUs, createContactUsSchemas, inits } from "../modules/contact-us/components/CreateContactUsHelper";
import Select from 'react-select';
import { postFijbInquiry } from "../services/fijb_inquiry.services";
import { InquiryContactUs } from "../modules/modals/inquiry-contact-us/InquiryContactUs";
import { Dropdown, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

export function ContactUsPage() {
    const intl = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const [currentSchema] = useState(createContactUsSchemas[0])
    const [initValues] = useState<ICreateContactUs>(inits)
    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false)
    const [countryCode, setCountryCode] = useState('+32');

    const subjectOptions = [
        { value: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION1'}), label: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION1'}) },
        { value: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION2'}), label: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION2'}) },
        { value: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION3'}), label: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION3'}) },
        { value: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION4'}), label: intl.formatMessage({id: 'CONTACT.US.SUBJECT.OPTION4'}) },
    ];
    const queryParams = new URLSearchParams(location.search);
    const subjectFromQuery = queryParams.get('subject');

    const handleDropdownCountryCode = (eventKey) => {
        setCountryCode(eventKey);
    };

    const onSubmit = async (values: ICreateContactUs, actions: FormikValues) => {
        setLoading(true);
        try {
            await handleContactUs(values);
            if (values.sent === true) {
                setShowModal(true);
                setIsSuccess(true);
                actions.resetForm();
                values.sent = false;
            } else {
                setShowModal(true);
                setIsSuccess(false);
            }
            setLoading(false);
        } catch (error) {
            // Handle errors here
            setLoading(false);
            console.error(error);
        }
    }

    const handleContactUs = async (values) => {
        const queryParams = {
            email: values.email,
            first_name: values.firstName,
            gsm: countryCode + values.gsm,
            housenumber: values.houseNumber,
            zipcode: values.zipCode,
            last_name: values.lastName,
            message: values.message,
            street: values.street,
            subject: values.subject,
        };
        try {
          const response = await postFijbInquiry(queryParams);
          if (response.subject === values.subject) {
            values.sent = true;
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

    const setDefaultSubject = (subjects, field) => {
        if (subjectFromQuery) {
            return subjects[0];
        }
        return subjects.find(
            (option) =>  {
                return option.value === field.value
            }
        ) || null;
    };

    const onCloseRedirect = () => {
        setShowModal(false);
        if (isSuccess) {
            navigate('/appointment');
        }
    }

    return (
        <>
            <InquiryContactUs showModal={showModal} isSuccess={isSuccess} onClose={() => onCloseRedirect()} />
            <div className='card'>
                <div className='card-body'>
                    <Formik
                        initialValues={initValues}
                        validationSchema={currentSchema}
                        onSubmit={onSubmit}
                    >
                    {({ values, setFieldValue }) => (
                    <Form className='row'>
                        <div className='col-2'>
                        </div>
                        <div className='col-8'>
                            <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                                <KTIcon iconName='pencil' className='fs-2x' />
                                <div className="d-flex flex-column">
                                    <span>{intl.formatMessage({id: 'CONTACT.US.INFO'})}</span>
                                </div>
                            </div>
                            <div className='w-100 center'>
                                <div className='pb-10 pb-lg-12'>
                                    <h2 className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'CONTACT.US.HEADER'})}</h2>
                                </div>

                                <div className='fv-row mb-5'>
                                    <label className='form-label required'>{intl.formatMessage({id: 'CONTACT.US.SUBJECT'})}</label>
                                    <Field
                                        name="subject"
                                    >
                                        {({ field }) => (<Select
                                            name={field.name}
                                            value={setDefaultSubject(subjectOptions, field)}
                                            options={subjectOptions}
                                            onChange={(selectedOption) => {
                                                setFieldValue(field.name, selectedOption ? selectedOption.value : '')
                                            }}
                                            placeholder={intl.formatMessage({id: 'CONTACT.US.SUBJECT'})}
                                            isSearchable={true}
                                            className="p-0 lh-lg form-control-lg form-control-solid"
                                        />
                                        )}
                                    </Field>
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='subject'>
                                        {(msg) => (
                                            <div style={{ color: 'red' }}>
                                            {intl.formatMessage({id: 'ERROR.SUBJECT.REQUIRED'})}
                                            </div>
                                        )}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-md-6 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.FIRST.NAME'})}</label>
                                        <Field name='firstName' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='firstName'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.FIRST.NAME.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-6 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.LAST.NAME'})}</label>
                                        <Field name='lastName' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='lastName'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.LAST.NAME.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-md-6 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.CONTACT.MOBILE'})}</label>
                                        <InputGroup>
                                            <Dropdown onSelect={handleDropdownCountryCode}>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    {countryCode}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                                                    <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Field name='gsm' className='form-control form-control-lg form-control-solid' />
                                        </InputGroup>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='gsm'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {msg && msg.includes('+32xxxxxxxx') ?
                                                    intl.formatMessage({ id: 'ERROR.CONTACT.GSM.INVALID.FORMAT' })
                                                : intl.formatMessage({ id: 'ERROR.CONTACT.PHONE.REQUIRED' })
                                                }
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-6 fv-row'>
                                        <label className='fs-6 fw-bold form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.CONTACT.EMAIL'})}</label>
                                        <Field name='email' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='email'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {msg && msg.includes('Invalid email address') ?
                                                    intl.formatMessage({ id: 'ERROR.CONTACT.EMAIL.INVALID.FORMAT' })
                                                : intl.formatMessage({ id: 'ERROR.CONTACT.EMAIL.REQUIRED' })
                                                }
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-md-6 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'CONTACT.US.STREET'})}</label>
                                        <Field name='street' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='street'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.STREET.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-3 fv-row'>
                                        <label className='fs-6 fw-bold form-label required'>{intl.formatMessage({id: 'CONTACT.US.HOUSE.NUMBER'})}</label>
                                        <Field name='houseNumber' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='houseNumber'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.HOUSE.NUMBER.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-3 fv-row'>
                                        <label className='fs-6 fw-bold form-label required'>{intl.formatMessage({id: 'APPOINTMENT.BOOKING.FINDER.POSTAL.CODE'})}</label>
                                        <Field name='zipCode' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='zipCode'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.POSTAL.CODE.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <label className='fs-6 fw-bold form-label required'>{intl.formatMessage({id: 'CONTACT.US.MESSAGE'})}</label>
                                    <Field
                                        as="textarea"
                                        name='message'
                                        className='form-control form-control-lg form-control-solid'
                                        rows={3}

                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='message'>
                                        {(msg) => (
                                            <div style={{ color: 'red' }}>
                                            {intl.formatMessage({id: 'ERROR.MESSAGE.REQUIRED'})}
                                            </div>
                                        )}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <div className='d-flex justify-content-end pt-7'>
                                        <button type="submit" className='btn btn-sm fw-bolder btn-primary' disabled={loading}>
                                            {!loading && intl.formatMessage({id: 'BUTTON.SEND'})}
                                            {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                {intl.formatMessage({id: 'BUTTON.PLEASE.WAIT'})}{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    )}
                    </Formik>
                </div>
            </div>
        </>
    );
}