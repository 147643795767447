import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";


export function PolicyPage() {
    const intl = useIntl();
    const [tab, setTab] = useState('TAB2')

    const privacyMessage = intl.formatMessage({ id: 'PRIVACY.POLICY.BODY' });
    const replacedPrivacyMessage = () => {
        const html = privacyMessage;
        return { __html: html };
    };

    const termsMessage = intl.formatMessage({ id: 'TERMS.OF.USE.BODY' });
    const replacedTermsMessage = () => {
        const html = termsMessage;
        return { __html: html };
    };

    const cookieMessage = intl.formatMessage({ id: 'COOKIE.POLICY.BODY' });
    const replacedCookieMessage = () => {
        const html = cookieMessage;
        return { __html: html };
    };

    return (
        <div className='card'>
            <div className='card-header card-header-stretch overflow-auto'>
                <ul
                    className='nav nav-stretch nav-line-tabs
                    fw-bold
                    border-transparent
                    flex-nowrap
                '
                    role='tablist'
                >
                    <li className='nav-item'>
                    <button
                        className={clsx(`nav-link cursor-pointer`, {active: tab === 'TAB1'})}
                        onClick={() => setTab('TAB1')}
                        role='tab'
                    >
                        {intl.formatMessage({id: 'PAGE.HEADER.PRIVACY.POLICY'})}
                    </button>
                    </li>
                    <li className='nav-item'>
                    <button
                        className={clsx(`nav-link cursor-pointer`, {active: tab === 'TAB2'})}
                        onClick={() => setTab('TAB2')}
                        role='tab'
                    >
                        {intl.formatMessage({id: 'PAGE.HEADER.TERMS.OF.USE'})}
                    </button>
                    </li>
                    <li className='nav-item'>
                    <button
                        className={clsx(`nav-link cursor-pointer`, {active: tab === 'TAB3'})}
                        onClick={() => setTab('TAB3')}
                        role='tab'
                    >
                        {intl.formatMessage({id: 'PAGE.HEADER.COOKIE.POLICY'})}
                    </button>
                    </li>
                </ul>
            </div>
            <div className='card-body'>
                <div className='tab-content pt-3'>
                    <div className={clsx('tab-pane', {active: tab === 'TAB1'})}>
                        <div className="mb-18">
                            <div className="mb-10">
                                <div className="text-center mb-15">
                                    <section id="section1">
                                        <h3 className="fs-2hx text-dark mb-5">{intl.formatMessage({id: 'PAGE.HEADER.PRIVACY.POLICY'})}</h3>
                                    </section>
                                </div>
                            </div>
                            <div className="fs-5 fw-semibold text-gray-600">
                                <p className="mb-8" dangerouslySetInnerHTML={replacedPrivacyMessage()} />
                            </div>
                        </div>
                    </div>
                    <div className={clsx('tab-pane', {active: tab === 'TAB2'})}>
                        <div className="mb-18">
                            <div className="mb-10">
                                <div className="text-center mb-15">
                                    <section id="section2">
                                        <h3 className="fs-2hx text-dark mb-5">{intl.formatMessage({id: 'PAGE.HEADER.TERMS.OF.USE'})}</h3>
                                    </section>
                                </div>
                            </div>
                            <div className="fs-5 fw-semibold text-gray-600">
                                <p className="mb-8" dangerouslySetInnerHTML={replacedTermsMessage()} />
                            </div>
                        </div>
                    </div>
                    <div className={clsx('tab-pane', {active: tab === 'TAB3'})}>
                        <div className="mb-18">
                            <div className="mb-10">
                                <div className="text-center mb-15">
                                    <section id="section3">
                                        <h3 className="fs-2hx text-dark mb-5">{intl.formatMessage({id: 'PAGE.HEADER.COOKIE.POLICY'})}</h3>
                                    </section>
                                </div>
                            </div>
                            <div className="fs-5 fw-semibold text-gray-600">
                                <p className="mb-8" dangerouslySetInnerHTML={replacedCookieMessage()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}