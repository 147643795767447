import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTIcon } from '../../../_metronic/helpers';

const CookieBanner = () => {
  const intl = useIntl();
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('cookiePolicyAccepted');
    if (hasAccepted) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, []);

  const acceptCookiePolicy = () => {
    setAccepted(true);
    localStorage.setItem('cookiePolicyAccepted', 'true');
  };

    return (
        <>
            { !accepted &&
                <>
                <div className='card fixed-bottom mw-100 m-0 bg-secondary'>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-11 text-center">
                                <span className="p-5">{intl.formatMessage({ id: 'COOKIE.POLICY.POPUP' })}</span>
                                <button type='button' className='btn btn-light-primary pr-10' onClick={acceptCookiePolicy}>
                                    {intl.formatMessage({ id: 'BUTTON.OK' })}
                                </button>
                            </div>
                            <div className="col-1 text-end">
                                <div className="btn btn-sm btn-icon btn-primary btn-active-color-default" onClick={acceptCookiePolicy}>
                                    <KTIcon iconName='cross' className='fs-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            }
        </>
    );

};

export default CookieBanner;