import * as Yup from 'yup'

export interface ICreateContactSyndic {
  firstName: string
  lastName: string
  contactPhone: string
  gsm: string
  email: string
  message: string
  sent: boolean
}

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const createContactSyndicSchemas = [
  Yup.object({
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    contactPhone: Yup.string().matches(
      /^\+(?:[0-9] ?){6,14}[0-9]$/,
      'Invalid phone number format. Please use the international format like +1234567890.'
    ).label('Phone'),
    gsm: Yup.string().required().matches(
      /^[0-9]{8,12}$/,
      'Invalid phone number format. Please use the international format like +32xxxxxxxx.'
    ).label('GSM'),
    email: Yup.string()
      .matches(emailRegex, 'Invalid email address')
      .required().label('Email'),
    message: Yup.string().label('Message'),
  })
]

const inits: ICreateContactSyndic = {
  firstName: "",
  lastName: "",
  contactPhone: "",
  gsm: "",
  email: "",
  message: "",
  sent: false,
}

export {createContactSyndicSchemas, inits}
