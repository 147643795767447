import axios from 'axios';

const postFijbInquiry = async (data) => {
    const API_URL = process.env.REACT_APP_URL;
    const API_ORIGIN = process.env.REACT_API_ORIGIN;
    const headers = {
        'Origin': API_ORIGIN,
    };
    try {
        const response = await axios.post(API_URL + 'fijb/inquiry', data, { headers });
        if (!response.data) {
            throw new Error('Failed to fetch messages');
        }
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch messages: ' + (error as Error).message);
    }
};

export { postFijbInquiry };