import * as Yup from 'yup'

export interface ICreateContactUs {
  subject: string
  firstName: string
  lastName: string
  gsm: string
  email: string
  street: string
  houseNumber: string
  zipCode: string
  message: string
  sent: boolean
}

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const createContactUsSchemas = [
  Yup.object({
    subject: Yup.string().required().label('Subject'),
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    gsm: Yup.string().required().matches(
      /^[0-9]{8,12}$/,
      'Invalid phone number format. Please use the international format like +32xxxxxxxx.'
    ).label('GSM'),
    email: Yup.string()
      .matches(emailRegex, 'Invalid email address')
      .required().label('Email'),
    street: Yup.string().required().label('Street'),
    houseNumber: Yup.string().required().label('House number'),
    zipCode: Yup.string().required().label('Zipcode'),
    message: Yup.string().required().label('Message'),
  })
]

const inits: ICreateContactUs = {
  subject: "",
  firstName: "",
  lastName: "",
  gsm: "",
  email: "",
  street: "",
  houseNumber: "",
  zipCode: "",
  message: "",
  sent: false,
}

export {createContactUsSchemas, inits}
