import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { PolicyPage } from '../pages/PolicyPage'
import { ContactUsPage } from '../pages/ContactUsPage'
import { ContactSyndicPage } from '../pages/ContactSyndicPage'

const PrivateRoutes = () => {
  const AppointmentWizardsPage = lazy(() => import('../modules/wizards/AppointmentWizardsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='contact-us' element={<ContactUsPage />} />
        <Route path='contact-syndic' element={<ContactSyndicPage />} />
        <Route path='policy' element={<PolicyPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
         {/* Lazy Modules */}
        <Route
          path='appointment'
          element={
            <SuspensedView>
              <AppointmentWizardsPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

