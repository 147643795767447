/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Languages } from '../../../../partials/layout/header-menus/Languages'
import { useIntl } from 'react-intl'

const ToolbarSaas: FC = () => {
  const intl = useIntl();
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <div className='d-flex align-items-center gap-2'>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      >
        <Languages />
      </div>

      <div className='d-flex align-items-center flex-shrink-0'>
        <div className='flex-shrink-0 '>
          <Link to='/contact-us' className='btn btn-sm btn-primary fw-bold'>
            {intl.formatMessage({id: 'BUTTON.CONTACT.US'})}
          </Link>
        </div>
      </div>
    </div>
  )
}

export {ToolbarSaas}
