import React, { createContext, useContext, useEffect, useState } from 'react';

type QueryParamContextType = {
    queryParams: {
      hash?: object;
    };
    removeHash: () => void;
    isHash: boolean;
  };

const QueryParamContext = createContext<QueryParamContextType>({ queryParams: {}, removeHash: () => {}, isHash: false });

export const QueryParamProvider = ({ children }) => {
  const [queryParams, setQueryParams] = useState({});
  const [isHash, setIsHash] = useState(false);

  useEffect(() => {
    const params = {};
    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      urlParams.forEach((value, key) => {
        params[key] = JSON.parse(atob(value));
      });
    }
    setQueryParams(params);
    cleanUrl(params);
  }, []);

  const cleanUrl = (params) => {
    if (params.hasOwnProperty('hash')) { 
        setIsHash(true);
        window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  const removeHash = () => {
    setQueryParams({});
    setIsHash(false);
  }

  return (
    <QueryParamContext.Provider value={{queryParams, removeHash, isHash}}>
      {children}
    </QueryParamContext.Provider>
  );
};

export const useQueryParams = () => useContext(QueryParamContext);